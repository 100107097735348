import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = {
  ref: "filter",
  class: "cp-admin-actions__wrapper cp-admin-control__wrapper cp-width__max--full justify-content-start cp-margin__b--small"
}
const _hoisted_3 = { class: "cp-flex align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_5 = { class: "cp-flex align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_7 = { class: "cp-flex align-items-center" }
const _hoisted_8 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_9 = {
  key: 0,
  style: {"margin":"0","padding":"0","white-space":"nowrap","list-style":"none"}
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_app_simple_checkbox = _resolveComponent("app-simple-checkbox")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_shift_modal = _resolveComponent("shift-modal")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createVNode(_component_title_return, {
              icon: "task-sheet",
              "icon-size": "19",
              "is-return": false
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Задачи ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("form", _hoisted_2, [
            (_ctx.selectRange)
              ? (_openBlock(), _createBlock(_component_form_select, {
                  key: 0,
                  modelValue: _ctx.selectRange.current.value ? _ctx.selectRange.current : _ctx.selectRange.list[0],
                  items: _ctx.selectRange.list,
                  class: "cp-width--200",
                  "padding-top-zero": "",
                  onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelect($event, { action: _ctx.selectRange.action, key: 'range' })))
                }, null, 8, ["modelValue", "items"]))
              : _createCommentVNode("", true),
            (_ctx.isProcessed && _ctx.isProcessedItem)
              ? (_openBlock(), _createBlock(_component_gui_form_checkbox, {
                  key: 1,
                  modelValue: _ctx.isProcessedItem.checked,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isProcessedItem.checked) = $event)),
                  label: "Просмотренные",
                  class: "cp-margin__b-no cp-margin__l--s cp-desc",
                  onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleCheckbox($event, _ctx.isProcessed.key, _ctx.isProcessed.action)))
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ], 512)
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount
          }, {
            "item-fullName": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'employees_schedule', params: { employeeId: item.employeeId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-market": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'shop_shift', params: { shopId: item.marketId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestMarket)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-client": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'customer_edit', params: { customerId: item.clientId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestClient)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-vacancy": _withCtx(({ value, item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: {
            name: 'template_edit',
            params: { customerId: item.clientId, vacancyId: item.vacancyId },
          },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-marketPhones": _withCtx(({ value, item }) => [
              (value && value.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (phone, index) => {
                      return (_openBlock(), _createBlock(_component_text_phone, {
                        key: `phone_${index}`,
                        tag: "li",
                        value: phone,
                        onPhoneClick: ($event: any) => (_ctx.makeCall(item.marketId, index))
                      }, null, 8, ["value", "onPhoneClick"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            "item-time": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: "app-table-link",
                onClick: ($event: any) => (_ctx.openShift(item))
              }, [
                (item.start)
                  ? (_openBlock(), _createBlock(_component_text_datetime, {
                      key: 0,
                      value: item.start,
                      "format-to": "H:mm"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" - "),
                (item.end)
                  ? (_openBlock(), _createBlock(_component_text_datetime, {
                      key: 1,
                      value: item.end,
                      "format-to": "H:mm"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_10)
            ]),
            "item-isProcessed": _withCtx(({ value, item }) => [
              _createVNode(_component_app_simple_checkbox, {
                "is-fill": true,
                "model-value": value,
                "true-value": true,
                "false-value": false,
                onUpdate: ($event: any) => (_ctx.updateIsProcessed(item.shiftId, $event))
              }, null, 8, ["model-value", "onUpdate"])
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount"]))
        : _createCommentVNode("", true),
      (_ctx.isShowShiftModal)
        ? (_openBlock(), _createBlock(_component_shift_modal, {
            key: 2,
            "shop-id": _ctx.shopId,
            "service-type-id": _ctx.serviceTypeId,
            "shift-id": _ctx.shiftIdForModal,
            onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShowShiftModal = false))
          }, null, 8, ["shop-id", "service-type-id", "shift-id"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
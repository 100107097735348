import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class FoundEmployeesFilter {
  filterHandlers = {
    update: 'foundEmployeesFilter/updateFilter',
    reset: 'foundEmployeesFilter/resetFilter',
    resetState: 'foundEmployeesFilter/resetFilterState',
    removeSelected: 'foundEmployeesFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('range');
    filter.setName('range', 'select_range');
    filter.setAction('range', 'foundEmployees/updateRange');
    filter.setList('range', [
      { id: 'today', value: 'Сегодня' },
      { id: 'tomorrow', value: 'Завтра' },
    ]);
    filter.setClearAction('range', '');
    filter.setCurrent('range', { id: 'today', value: 'Сегодня' });

    filter.createCheckboxEntity('isProcessed');
    filter.setTitle('isProcessed', 'Просмотренные');
    filter.setName('isProcessed', 'checkbox_isProcessed');
    filter.setAction('isProcessed', 'foundEmployees/updateIsProcessedFilter');
    filter.setListElement('isProcessed', {
      id: 'isProcessed',
      name: 'Просмотренные',
      checked: false,
    });

    this.filterModel = filter.filterModel;
  }
}

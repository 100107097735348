import { Module } from 'vuex-module-decorators';
import store from '@/store';
import FilterModule from '@/store/filter';

@Module({ dynamic: true, store, name: 'foundEmployeesFilter', namespaced: true })
export default class FoundEmployeesFilter extends FilterModule {
  constructor(module: FoundEmployeesFilter) {
    super(module);
  }
}

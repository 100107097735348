import { makeFormRequest, makeGetRequest } from '@/api/requests';
import API from '@/config/api-routers';
import { AssemblerUrl } from '@/lib/UtilsApi';

/**
 * Get list
 */
export const getFoundEmployeesList = async (filter: string, pageAmountItems: string) => {
  const assemblerUrl = new AssemblerUrl(API.foundEmployees.getList);
  const url = assemblerUrl.addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Update IsProcessed
 *
 * @param id
 * @param data
 */
export const updateIsProcessed = async (id: string, data: any) => {
  const url = API.foundEmployees.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * found employees store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getFoundEmployeesList, updateIsProcessed } from '@/api/foundEmployees';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import filterModel from './filter';
import { prepareList } from '@/lib/FoundEmployees';
import FoundEmployeesFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'foundEmployees';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FoundEmployeesModule extends PageBaseModule {
  shift: {} = {};
  filter: Filter;

  constructor(module: FoundEmployeesModule) {
    super(module);

    const page = new PageEntity();

    page.values.actionPagination = 'foundEmployees/updatePage';
    this.pageSettings = page.values;
    this.pageSettings.actionsHandler = {
      additional: {
        fullName: 'foundEmployees/getById',
        market: 'foundEmployees/getById',
        client: 'foundEmployees/getById',
        vacancy: 'foundEmployees/getById',
        time: 'foundEmployees/getShiftById',
      },
    };

    const filter = new filterModel();
    this.filter = getModule(FoundEmployeesFilter);
    this.filter.setFilterName('foundEmployeesFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SHIFT(shift: {}) {
    this.shift = shift;
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = this.filter.filterSettings.filter;
      const result = await getFoundEmployeesList(filter, itemsQuery);
      await this.context.dispatch('setList', result.table);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  setList(table: any) {
    this.context.commit('SET_TABLE', prepareList(table));
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async updateSelectRange(selected: {}) {
    this.context.commit('SET_FILTER_RANGE', selected);
    this.getList();
  }

  @Action({ rawError: true })
  setShift(shift: {}) {
    this.context.commit('SET_SHIFT', shift);
  }

  @Action({ rawError: true })
  async updateIsProcessedFilter(params: { key: string; checkbox: { bool: boolean; id: string; name: string } }) {
    await this.filter.updateCheckbox(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async updateRange(params: { key: string; value: { id: number; value: string } }) {
    await this.filter.updateSelect(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async updateIsProcessed(params: { id: string; bool: boolean }) {
    try {
      const data = {
        isProcessed: params.bool ? 1 : 0,
      };
      const result = await updateIsProcessed(params.id, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }
}

export default getModule(FoundEmployeesModule);

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

/**
 * Returns processed shops info for shops table
 *
 * @param {Object} table employees data
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface) {
  const uiTable = new UITable(table as any);

  return uiTable
    .removeColumn('start')
    .removeColumn('end')
    .removeColumn('isTest')
    .removeColumn('isTestClient')
    .removeColumn('isTestMarket')

    .addColumnByIndex(3, { id: 'time', name: 'Время', visible: true })

    .setProperty('isProcessed', 'name', 'Просмотрено')
    .setProperty('market', 'name', 'Объект')

    .getTable() as any;
}


import { ref } from 'vue';
import { Component, Vue } from '@/lib/decorator';
import TitlePage from '@/components/Title.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import AppFilterClearBtn from '@/layouts/partials/AppFilterClearBtn.vue';
import FilterBlock from '@/components/Filter.vue';
import ModalBlock from '@/components/Modal.vue';
import FormSelect from '@/components/form/Select/index.vue';

import FoundEmployeesModule from '@/store/tasks/foundEmployees';
import ShopShiftsModule from '@/store/shops/shift';
import EmployeesCalendarModule from '@/store/employees/calendar';
import { EventBus } from '@/lib/event-bus';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TasksEntityModule from '@/store/tasks/entity';
import TextPhone from '@/components/table-items/TextPhone.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { makePhoneCall } from '@/lib/util/phone';
import { getShopPhones } from '@/api/shop';
import { FilterEntityModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { ShiftModalParamsInterface } from '@/store/shops/shiftModalParams';
import { modalParamsInterface } from '@/store/employees/calendarModalParams';
import { Checkbox } from '@/interfaces/filter.interface';
import ShiftModal from '@/views/shops/shift/form/shiftModal.vue';
import { GuiFormCheckbox, GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    GuiFormCheckbox,
    AppCol,
    AppRow,
    AppTable,
    AppSimpleCheckbox,
    TextDatetime,
    TextPhone,
    TabsNav,
    TitlePage,
    AppFilterClearBtn,
    FilterBlock,
    ModalBlock,
    FormSelect,
    TitleReturn,
    ShiftModal,
  },
})
export default class FoundEmployeesMain extends Vue {
  filter = ref(null);

  isShowShiftModal = false;
  shopId?: string;
  serviceTypeId?: string;
  shiftIdForModal?: string;

  get tabsNav(): TabsNavInterface {
    return TasksEntityModule.tabsNav;
  }

  get currentTab(): string {
    return TasksEntityModule.tabsNav.foundEmployees?.id;
  }

  get settings(): PageInterface {
    return FoundEmployeesModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return FoundEmployeesModule.filter.filterSettings;
  }

  get isProcessed(): FilterEntityModel {
    return FoundEmployeesModule.filter.filterSettings.filterModel.isProcessed;
  }

  get isProcessedItem(): Checkbox {
    return (FoundEmployeesModule.filter.filterSettings.filterModel.isProcessed.list as Checkbox[])[0];
  }

  get selectRange(): FilterEntityModel {
    return FoundEmployeesModule.filter.filterSettings.filterModel.range;
  }

  get modalParams(): modalParamsInterface {
    return EmployeesCalendarModule.modalParams;
  }

  get modalParamsMarket(): ShiftModalParamsInterface {
    return ShopShiftsModule.modalParams;
  }

  get editEntityType(): string {
    return EmployeesCalendarModule.modalParams.edit.entityType;
  }

  async handleSelect(selected: {}, params: { action: string; key: string }): Promise<void> {
    FoundEmployeesModule.SET_PAGE(1);
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await FoundEmployeesModule.getList();
  }

  async handleCheckbox(value: { target: { checked: boolean } }, key: string, actionName: string): Promise<void> {
    await this.$store.dispatch(actionName, {
      key,
      checkbox: {
        key,
        value: value.target.checked,
      },
    });

    await FoundEmployeesModule.getList();
  }

  updateIsProcessed(id: string, bool: boolean): void {
    FoundEmployeesModule.updateIsProcessed({ id, bool });
  }

  openShift(item: { marketId: number; serviceTypeId: number; shiftId: number }): void {
    this.shopId = item.marketId?.toString();
    this.serviceTypeId = item.serviceTypeId?.toString();
    this.shiftIdForModal = item.shiftId.toString();
    this.isShowShiftModal = true;
  }

  selectAmount(value: string): void {
    FoundEmployeesModule.updatePageAmountItems(value);
  }

  savedEditShift(): void {
    FoundEmployeesModule.getList();
  }

  async makeCall(shopId: string, phoneIndex: number): Promise<void> {
    try {
      const phones = await getShopPhones(shopId);
      makePhoneCall(phones, phoneIndex);
    } catch (error) {
      console.error(error);
    }
  }

  mounted(): void {
    TasksEntityModule.initTabsItems();
    EventBus.$on('savedShift', this.savedEditShift);
    FoundEmployeesModule.init();
  }

  destroyed(): void {
    EventBus.$off('savedShift', this.savedEditShift);
  }
}
